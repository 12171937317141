import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-Menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  expanded: boolean;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() categoryArray: any;
  @Input() data: any;
  @Input() type: any;
  @Input() color: boolean=false;
  display: boolean;
  image:any;
  constructor(
    public router: Router,
    private commonService: CommonService,
    private ren: Renderer2

    ) {
  }

  public serverPath = environment.commonImageApi + 'category/image/150x150/';
  
  ngOnInit() {

    if (window.innerWidth < 1281) {
      this.display = false;
    }else{
      this.display=true
    }


  }
  goToCategory(menu) {

    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

  }


  public onItemSelected(item: any) {
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }


  public redirectTo(subchildState) {
    this.router.navigate([subchildState.state], { queryParams: { category: subchildState.queryState } });
  }

  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if(el.children.length > 0){
          if(el.children[0].classList.contains('mega-menu')){
            el.classList.add('mega-menu-pane');
          }
        }
    });
  }

  closeMyMenu(){
    this.trigger.closeMenu();
   // console.log('close')
  }

  open(menu){
    menu.openMenu();
    }

    enteredButton = false;
    isMatMenuOpen = false;
    isMatMenu2Open = false;
    prevButtonTrigger;
    buttonLeave(trigger, button) {
      setTimeout(() => {
        if (this.enteredButton && !this.isMatMenuOpen) {
          trigger.closeMenu();
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
        } if (!this.isMatMenuOpen) {
          trigger.closeMenu();
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
        } else {
          this.enteredButton = false;
        }
      }, 100)
    }

    buttonEnter(trigger) {
      setTimeout(() => {
        if(this.prevButtonTrigger && this.prevButtonTrigger != trigger){
          this.prevButtonTrigger.closeMenu();
          this.prevButtonTrigger = trigger;
          this.isMatMenuOpen = false;
          this.isMatMenu2Open = false;
          trigger.openMenu();
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
        }
        else if (!this.isMatMenuOpen) {
          this.enteredButton = true;
          this.prevButtonTrigger = trigger
          trigger.openMenu();
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
        }
        else {
          this.enteredButton = true;
          this.prevButtonTrigger = trigger
        }
      })
    }
    timedOutCloser;
    mouseEnter(trigger) {
      if (this.timedOutCloser) {
        clearTimeout(this.timedOutCloser);
      }
      trigger.openMenu();
    }
  
    mouseLeave(trigger) {
      this.timedOutCloser = setTimeout(() => {
        trigger.closeMenu();
      }, 50);
    }
    
    activeTab: string = 'featured';

    featuredBrands = [
      { name: 'Fur Pro', logo: 'https://www.zigly.com/media/amasty/shopby/option_images/slider/Furpro.png' },
      { name: 'Zigly', logo: 'https://www.zigly.com/media/amasty/shopby/option_images/slider/ZL-logo.png' },
      { name: 'Kong', logo: 'https://www.zigly.com/media/amasty/shopby/option_images/slider/Kong.png' },
      { name: 'Fur Pro', logo: 'https://www.zigly.com/media/amasty/shopby/option_images/slider/Furpro.png' },
      { name: 'Zigly', logo: 'https://www.zigly.com/media/amasty/shopby/option_images/slider/ZL-logo.png' },
      { name: 'Kong', logo: 'https://www.zigly.com/media/amasty/shopby/option_images/slider/Kong.png' },

      
      // Add more featured brands
    ];
  
    otherBrands = [
      { name: 'Brand A', logo: 'https://www.zigly.com/media/amasty/shopby/option_images/slider/Kong.png' },
      { name: 'Brand B', logo: 'https://www.zigly.com/media/amasty/shopby/option_images/slider/ZL-logo.png' },
      { name: 'Brand C', logo: 'https://www.zigly.com/media/amasty/shopby/option_images/slider/Furpro.png' },
      { name: 'Brand A', logo: 'https://www.zigly.com/media/amasty/shopby/option_images/slider/Kong.png' },
      { name: 'Brand B', logo: 'https://www.zigly.com/media/amasty/shopby/option_images/slider/ZL-logo.png' },
      { name: 'Brand C', logo: 'https://www.zigly.com/media/amasty/shopby/option_images/slider/Furpro.png' },
    ];

    setActiveTab(tab: string) {
      this.activeTab = tab;
    }
  

}
