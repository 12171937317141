<div *ngIf="data.categoryMenuType!=6;">
<ng-container *ngIf="list && list.length >0">
  <div class="theme-container" *ngFor="let subChild of list" (click)="goToCategory(subChild)">

    <span [style.color]="data.categoryColor" style="font-size: 15px;line-height: 30px;" class="subMenus pointer theme-container" *ngIf="display && data.categoryMenuType===2">  
      {{subChild.categoryName}}
    </span>

    <span class="subMenus pointer theme-container" style="font-size: 15px;line-height: 30px;" *ngIf="display && data.categoryMenuType!==2">  
      {{subChild.categoryName}}
    </span>

    <a *ngIf="!display && data.categoryMenuType===2" style="font-size: 15px;line-height: 30px;" (click)="goToCategory(subChild)" [style.color]="data.categoryColor" mat-menu-item>
      {{subChild.categoryName}}</a>

      <a *ngIf="!display && data.categoryMenuType!==2" style="font-size: 15px;line-height: 30px;" (click)="goToCategory(subChild)"  mat-menu-item>
        {{subChild.categoryName}}</a>

    <calsoft-nested-child [data]="data" [list]="subChild.subcategories"></calsoft-nested-child>

  </div>
</ng-container>
</div>

<div *ngIf="data.categoryMenuType==6;">
  <ng-container *ngIf="list && list.length >0">
    <div class="theme-container" *ngFor="let subChild of list" (click)="goToCategory(subChild)">
  
      <span [style.color]="data.categoryColor" style="font-size: 12px;line-height: 30px;" class="subMenus pointer theme-container" *ngIf="display && data.categoryMenuType===2">  
        {{subChild.categoryName}}
      </span>
  
      <span class="subMenus pointer theme-container" style="font-size: 12px;line-height: 30px;" *ngIf="display && data.categoryMenuType!==2">  
        {{subChild.categoryName}}
      </span>
  
      <a *ngIf="!display && data.categoryMenuType===2" style="font-size: 12px;line-height: 30px;" (click)="goToCategory(subChild)" [style.color]="data.categoryColor" mat-menu-item>
        {{subChild.categoryName}}</a>
  
        <a *ngIf="!display && data.categoryMenuType!==2" style="font-size: 12px;line-height: 30px;" (click)="goToCategory(subChild)"  mat-menu-item>
          {{subChild.categoryName}}</a>
  
      <calsoft-nested-child [data]="data" [list]="subChild.subcategories"></calsoft-nested-child>
  
    </div>
  </ng-container>
  </div>